.Content_wrapper {
  background: white;
  overflow: hidden;
  border-radius: 10px;
}
.Top_panels{
  display: flex;

  .Top_panel_left, .Top_panel_right {
    flex-basis: 50%;

    h1 {
      color: white;
    }
  }

  .Top_panel_left{
    background: linear-gradient(30deg, #8e9ce5, #6f82e1);
    padding: 16px 18px;
  }
  .Top_panel_right{
    background: linear-gradient(30deg, #a38feb, #745fc1);
    padding: 16px 18px;
  }
}
.Link_wrapper{
  padding: 8px 12px ;
  padding-top: 16px;
  border-radius: 12px;
  background-color: white;
  margin-bottom: 4px;
}
.Table_wrapper{
  padding: 16px 18px;
}
.Balance_total{
  font-size: 34px;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .Top_panels{
  flex-wrap: wrap;
  .Top_panel_left, .Top_panel_right {
    flex-basis: 100%;

  }
  }
}
