#AppBar {
  width: 100%;
  background: white;
  border-bottom: 1px solid #f0f0f0;
  .Header_inner {
    display: flex;
    align-items: center;
    background: white;
    padding-right: 28px;

    > .ant-menu {
      border-bottom: none;
    }

    .Fill {
      flex: 1;
    }
    .AppBar_logo {
      img {
        width: 45px;
      }
    }
  }

}

.Banner {
  width: 100%;
  height: 32px;
  background-color: #b1e1ff;
  display: flex;
  align-items: center;
  justify-content: center;

  .Banner_inner {
    text-align: center;
    padding: 2px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}