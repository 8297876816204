body {
  background-image: linear-gradient(
    to right bottom,
    #fef1d3,
    #ffe3cc,
    #ffd5cf,
    #ffcbdb,
    #edc5ea,
    #d8caf8,
    #bdd0ff,
    #a1d6ff,
    #94e2fe,
    #92ecf7,
    #9ef5ea,
    #b4fbdc
  );
  background-repeat: no-repeat;
  height: 100vh;
}

.PageWrapper {
  padding: 24px;
  width: 1100px;
  max-width: 100%;
  margin: auto;
}
.bold {
  font-weight: bold;
}
.Register_container {
  margin-left: 12px;
  margin-right: 12px;
}
.Register_wrapper {
  display: flex;
  background-color: white;
  border-radius: 12px;
  width: 800px;
  max-width: 100%;
  justify-content: center;
  margin: auto;
  margin-top: 120px;
  box-shadow: 2px 2px 12px #cfcfcf;

  .Register_left {
    flex-basis: 50%;
    padding: 48px 60px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
  .Register_right {
    flex-basis: 50%;
    padding: 48px 60px;
  }
}
.Partner_rules {
  font-size: 16px;
  padding-inline-start: 16px;
  li {
    margin-bottom: 12px;
    color: #5d5d5d;
  }
}

@media screen and (max-width: 700px) {
  .Register_wrapper {
    flex-wrap: wrap;
    .Register_left,
    .Register_right {
      flex-basis: 100%;
    }
    .Register_left {
      border-right: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}
